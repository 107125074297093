<template>
    <div class="full-height color-txt">
        <s-form @submit="addItem" ref="form">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary fs-lg-4 font-poppins-semibold">Dehorning/Disbudding</h3>
                </div>
                <div class="col-lg-2 mt-lg-5">
                    <validated-ajax-vue-select class="c-input-select input-border-primary field-required" placeholder="Shed"
                                               v-model="shed_number" @input="loadBullOptions" label="shed" :rules="{required:true}"
                                               :url="shedOptions"></validated-ajax-vue-select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-primary fs-lg-1 font-poppins-medium mb-3">Enter Individually</h4>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select :options="bullOptions" @input="loadDetails" :rules="{required:true}" class="field-required c-input-select"
                                          label="Animal No / Name" v-model="details.animalno_or_name"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-input disabled label="Renumber" v-model="details.renumber"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input disabled label="Shed No" v-model="details.shed_number"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input disabled label="Breed" v-model="details.breed"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" disabled
                                           class="c-input-datepicker" label="Date of Birth" v-model="details.dob"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-input disabled label="Age" v-model="details.age"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select :url="dehorningOptions"
                                               class="c-input-select" v-model="model.dehorning_type" label="Dehorning Type"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" v-model="model.dehorningdate"
                                           class="c-input-datepicker field-required" :rules="rules.DehornDate" label="Dehorning Date"></validated-date-picker>
                </div>
            </div>
            <div class="row mt-lg-9">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                            <btn text="Cancel" design="basic-b" type="reset" @click="BackToHome" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import masterURLs from '../../../data/masterURLs';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'DehorningFirstPage',
    data () {
        return {
            shed_number: '',
            loading: false,
            URL: urls.dehorning.addEdit,
            dehorningOptions: masterURLs.master.dehorningType.vueSelect,
            details: {
                renumber: '',
                breed: '',
                dob: '',
                shed_number: '',
                animalno_or_name: '',
                age: ''

            },
            model: {
                bull: '',
                dehorning_type: '',
                dehorningdate: ''
            },
            shedOptions: masterURLs.master.shed.vueSelect + '?animal=bull',
            bullOptions: [],
            rules: {

                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                },
                DehornDate: {
                    required: true,
                    customValidator: (value) => {
                        return this.DhorningValidation(value);
                    }
                }

            }
        };
    },
    watch: {
        shed_number (newValue, oldValue) {
            if (newValue) {
                this.clearAllData();
            }
        }
    },

    methods: {
        DhorningValidation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        },
        clearAllData () {
            this.details.renumber = '';
            this.details.breed = '';
            this.details.dob = '';
            this.details.shed_number = '';
            this.details.animalno_or_name = '';
            this.details.age = '';

            this.model.bull = '';
            this.model.dehorning_type = '';
            this.model.dehorningdate = '';
            this.$refs.form.reset();
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        async loadBullOptions () {
            this.dataclear();
            const response = await axios.get(urls.bulls.vueSelect + '?shed_number=' + this.shed_number);
            this.bullOptions = response.data.data;
        },
        async loadDetails () {
            const response = await axios.form(urls.bulls.details, { id: this.details.animalno_or_name });
            const data = response.data.data;
            this.model.bull = data.id;
            this.details.age = data.age;
            this.details.renumber = data.renumber;
            this.details.breed = data.breed.breed_name;
            this.details.dob = data.dob;
            this.details.shed_number = data.shed_number;
            // this.details.animalno_or_name = data.animalno_or_name;
        },
        dataclear () {
            this.details.age = '';
            this.details.dob = '';
            this.details.renumber = '';
            this.details.breed = '';
            this.details.shed_number = '';
            this.details.animalno_or_name = '';
            this.model.dehorning_type = '';
            this.model.dehorningdate = '';
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/semen-station/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        Validation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }

            return true;
        }
    }
};
</script>

<style scoped>

</style>
